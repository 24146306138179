.projects {
  color: #1a1a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 80vh;
}

.projects-content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* Magic Card Css */

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 300px;
  /* --card-width: 500px; */
}

.magic-card {
  backdrop-filter: blur(5px);
  position: relative;
  padding: 2% 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 70%;
  margin: 0 auto 100px auto;
  cursor: pointer;
  border-radius: 6px;

  color: #fff !important;
}

.magic-card-img {
  width: 50%;
  margin-left: 2%;
  object-fit: cover;
  border-radius: 6px;
  flex-grow: 1;
}

.magic-card:hover {
  color: #1a1a1a !important;
  border: 1px solid #ea4c46;
  transition: 0.5s;
}
.magic-card:hover:before,
.magic-card:hover:after {
  animation: none;
  opacity: 0;
}

.magic-card::before {
  content: "";
  width: 101%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate),
    #1a1919,
    #0e0d0d 43%,
    #5f5453
  );
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -0.5%;
  animation: spin 5s linear infinite;
  opacity: 0.2;
}

.magic-card::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(
    var(--rotate),
    #ec031a,
    #02008e 43%,
    #ef473a
  );
  opacity: 1;
  transition: opacity 0.5s;
  animation: spin 5s linear infinite;
  opacity: 1;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}

.magic-card-content {
  width: 40%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: var(--card-height);
}

.magic-card-company {
  font-family: "Bruno Ace SC", cursive !important;
  letter-spacing: 3px !important;
  text-decoration: underline;
}

.magic-card-description {
  text-align: justify;
  width: 75%;
  margin-top: 50%;
}

.magic-card:hover .magic-card-description {
  /* color: #1976d2; */
  transition: color 1s;
}

.note {
  font-family: "Open Sans", sans-serif;
  font-size: 11px !important;
  font-weight: 600 !important;
  user-select: none;
  opacity: 0.8;
}

.magic-card:hover .note {
  color: #ea4c46;
  transition: color, 1s;
}

@media screen and (max-width: 1023px) {
  .magic-card-company {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 900px) {
  :root {
    --card-height: 200px;
  }
  .magic-card-content {
    width: 45%;
  }
  .magic-card-company {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 750px) {
  :root {
    --card-height: 250px;
  }
  .magic-card {
    flex-direction: column;
  }
  .magic-card-img {
    width: 100%;
    margin-left: 0;
    margin-bottom: 2%;
  }
  .magic-card-content {
    width: 80%;
  }
}
