#logo-animated {
  display: block;
  margin: 0 auto;
  animation: logo-fill-anim 2s ease forwards 3.5s;
  width: 80%;
  z-index: 100;
}

#logo-animated path:nth-child(1) {
  stroke-dasharray: 1820.71630859375px;
  stroke-dashoffset: 1820.71630859375px;
  animation: logo-line-anim 2s ease forwards;
}

#logo-animated path:nth-child(2) {
  stroke-dasharray: 774.7650756835938px;
  stroke-dashoffset: 774.7650756835938px;
  animation: logo-line-anim 2s ease forwards 0.3s;
}

#logo-animated path:nth-child(3) {
  stroke-dasharray: 462.49322509765625px;
  stroke-dashoffset: 462.49322509765625px;
  animation: logo-line-anim 2s ease forwards 0.6s;
}

#logo-animated path:nth-child(4) {
  stroke-dasharray: 898.8224487304688px;
  stroke-dashoffset: 898.8224487304688px;
  animation: logo-line-anim 2s ease forwards 0.9s;
}

#logo-animated path:nth-child(5) {
  stroke-dasharray: 2879.785888671875px;
  stroke-dashoffset: 2879.785888671875px;
  animation: logo-line-anim 2s ease forwards 1.2s;
}

#logo-animated path:nth-child(6) {
  stroke-dasharray: 721.0817260742188px;
  stroke-dashoffset: 721.0817260742188px;
  animation: logo-line-anim 2s ease forwards 1.5s;
}

#logo-animated path:nth-child(7) {
  stroke-dasharray: 774.7662353515625px;
  stroke-dashoffset: 774.7662353515625px;
  animation: logo-line-anim 2s ease forwards 1.8s;
}

#logo-animated path:nth-child(8) {
  stroke-dasharray: 898.8167724609375px;
  stroke-dashoffset: 898.8167724609375px;
  animation: logo-line-anim 2s ease forwards 2.1s;
}

@keyframes logo-line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes logo-fill-anim {
  from {
    fill: transparent;
  }
  to {
    fill: #1a1a1a;
  }
}

@media screen and (max-width: 600px) {
  #logo-animated {
    width: 40%;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  #logo-animated {
    width: 22%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1023px) {
  #logo-animated {
    width: 30%;
  }
}
