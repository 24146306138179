.bouncing-text {
  position: relative;
  display: flex;
  font-size: 50px;
  font-family: arial black;
  min-height: 40vh;
  justify-content: center;
  align-items: center;
}

.m,
.e1,
.s1,
.s2,
.a,
.g,
.e2,
.s3,
.e3,
.n,
.t,
.sym {
  color: #ea4c46;
  text-shadow: 0 3px #e3211a, 0 5px #e3211a, 0 7px #e3211a;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-55px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  58% {
    transform: scale(1, 1) translateY(-7px);
  }
  65% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.m {
  animation: bounce 1s ease 1;
}
.e1 {
  animation: bounce 1s ease 1 0.1s;
}
.s1 {
  animation: bounce 1s ease 1 0.2s;
}
.s2 {
  animation: bounce 1s ease 1 0.3s;
}
.a {
  animation: bounce 1s ease 1 0.4s;
}
.g {
  animation: bounce 1s ease 1 0.5s;
}
.e2 {
  animation: bounce 1s ease 1 0.6s;
}
.s3 {
  animation: bounce 1s ease 1 0.7s;
}
.e3 {
  animation: bounce 1s ease 1 0.8s;
}
.n {
  animation: bounce 1s ease 1 0.9s;
}
.t {
  animation: bounce 1s ease 1 1s;
}
.sym {
  animation: bounce 1s ease 1 1.1s;
}

@media (max-width: 450px) {
  .bouncing-text {
    font-size: 30px;
  }
}

@media (max-width: 300px) {
  .bouncing-text {
    font-size: 20px;
  }
}
