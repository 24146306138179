.sidenav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.ProfilePic-card {
  background-color: #161616b4 !important;
  width: 100%;
}

.ProfilePic-header {
  text-align: center;
  padding: 10px 0 !important;
}

.email {
  padding: 10px 0 !important;
}

.email-text {
  text-align: center;
  color: #1a1a1a;
  font-size: 11px;
}

.nav-links {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #1a1a1a;
}

.social-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0;
  border-bottom: 5px solid #00000069;
}

.css-1g4ac1o-MuiTabs-root {
  flex-grow: 1;
}

.css-jpln7h-MuiTabs-scroller {
  display: flex !important;
  flex-direction: column;
  flex-grow: 1 !important;
}

.css-lfwcke-MuiTabs-flexContainer {
  flex-grow: 1;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .email-text {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1799px) {
  .email-text {
    font-size: 11px !important;
    letter-spacing: 0 !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1499px) {
  .css-nrdprl-MuiTypography-root {
    font-size: 15px !important;
  }
}
