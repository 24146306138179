.about {
  /* background-color: rgba(0, 0, 0, 0.8); */
  /* backdrop-filter: blur(5px); */
  color: #1a1a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.about-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 80vh;
}

.about-content {
  width: 99%;
  margin-left: 1%;
}

.about-timeline {
  width: 95%;
  text-align: center;
  margin: 0 auto;
}

.timeline-content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90%;
  margin-top: 5%;
}

.timeline-container {
  width: 60%;
  height: 100px;
  margin: 0 auto;
  color: #000;
  font-weight: bold;
  backdrop-filter: blur(10px);
}

.timeline-card {
  width: 70%;
}

.timeline-card-header {
  color: #ea4c46;
  font-family: "Bruno Ace SC", cursive !important;
  letter-spacing: 3px !important;
}

.timeline-card-description {
  font-size: 18px !important;
  letter-spacing: 1px !important;
}

@media screen and (max-width: 1800px) {
  .about-container {
    min-height: 100vh;
  }
  .about-content {
    width: 99%;
  }
  .about-timeline {
    width: 99%;
    margin-left: 1%;
  }
}

@media screen and (max-width: 760px) {
  .timeline-container {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .timeline-card {
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .timeline-card-header {
    font-size: 18px !important;
  }
  .timeline-card-description {
    font-size: 14px !important;
  }
}
