.contact {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.contact-form {
  color: #1a1a1a;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 90vh;
}

.contact-content {
  width: 90%;
  margin-left: 1%;
}

.contact-map {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  height: 50vh;
}

.form-container {
  width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-status {
  width: 90%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #5eef3a;
  background-color: #000000c4;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-input {
  width: 70%;
  margin: 2% !important;
  backdrop-filter: blur(10px);
  box-shadow: 5px 10px 10px 5px rgba(1, 1, 1, 0.7);
}
.MuiOutlinedInput-notchedOutline {
  border-color: #ef473a !important;
}

.form-button {
  backdrop-filter: blur(10px);
  width: 40%;
  color: #ef473a !important;
  align-self: center;
  border: 1px solid #ef473a !important;
  font-weight: 600 !important;
  letter-spacing: 5px !important;
  box-shadow: 5px 10px 10px 2px rgba(1, 1, 1, 0.7);
}

.form-button:hover {
  font-family: "Caveat", cursive !important;
  background-color: #ef473a !important;
  color: #1a1a1a !important;
  transition: font-family, color, background-color, box-shadow 0.5s ease-in-out !important;
  box-shadow: 2px 5px 5px 1px rgba(1, 1, 1, 0.7);
}

.map {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  position: relative;
}

.pin {
  width: 200px;
  display: flex;
  align-items: center;
  color: #fff;
}

.pin-icon {
  font-size: 36px !important;
}

.pin-text {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: 5px;
}

.address {
  background-color: #000000c4;
  color: #fff;
  padding: 3%;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.8;
  text-transform: uppercase;
  z-index: 1;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #1a1a1a !important;
  color: #1a1a1a !important;
}

@media (max-width: 768px) {
  .contact-form {
    width: 100%;
  }
  .contact-map {
    width: 100%;
  }
  .form-input {
    width: 90%;
  }
  .form-button {
    width: 50%;
  }
  .form-status {
    width: 100%;
  }
}
