@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Caveat&family=Dancing+Script&display=swap");

.App {
  display: flex;
  flex-direction: column;
  width: 90% !important;
  margin-right: initial !important;
}

.code-tags {
  font-family: "Caveat", cursive;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 10px;
  letter-spacing: 5px;
  opacity: 0.8;
  user-select: none;
  align-self: flex-start;
  text-align: initial;
}

.headers {
  font-weight: 600 !important;
  font-family: "Bruno Ace SC", cursive !important;
  margin: 0 0 0 1% !important;
  padding: 0;
  width: 99%;
  backdrop-filter: blur(5px);
}

/*1024px 1400px for body*/

@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .App {
    width: 90% !important;
  }
}

@media screen and (max-width: 1023px) {
  .App {
    width: 100% !important;
  }
}
