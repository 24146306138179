.home {
  color: #1a1a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.home-content {
  width: 75%;
}

.contact-btn-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
}

.btn-contact {
  background-color: transparent;
  backdrop-filter: blur(10px);
  color: #1a1a1a;
  width: 25%;
  padding: 0.5%;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border: 1px solid #ea4c46;
  transition: 0.6s;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 5px 10px 10px 2px rgba(1, 1, 1, 0.7);
}

.btn-contact:hover {
  background-color: #ea4c46;
  color: #1a1a1a;
  box-shadow: 2px 5px 5px 1px rgba(1, 1, 1, 0.7);
  transition: 0.5s;
}

@media screen and (max-width: 1023px) {
  .home {
    min-height: 96.5vh;
  }
}

@media screen and (max-width: 739px) {
  .btn-contact {
    width: 50%;
  }
}

@media screen and (max-width: 430px) {
  .btn-contact {
    width: 75%;
  }
}

@media screen and (max-width: 355px) {
  .btn-contact {
    font-size: 16px;
    width: 100%;
  }
}
