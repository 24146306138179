.skills-carousel-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

figure {
  display: grid;
  width: 50px;
  align-items: center;
}

figure > * {
  grid-area: 1/1;
  transition: 0.3s;
}
.skills-carousel-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  cursor: pointer;
}

figure:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 1800px) {
  /* image css */
}

@media screen and (max-width: 1150px) {
  /* image css */
}

@media screen and (max-width: 850px) {
  /* image css */
}

@media screen and (max-width: 760px) {
  /* image css */
}

@media screen and (max-width: 660px) {
  /* image css */
}

@media screen and (max-width: 510px) {
  /* image css */
}

@media screen and (max-width: 460px) {
  /* image css */
}
