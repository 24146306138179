.bouncing-letters {
  width: 75%;
  font-size: 76px;
  font-family: "Bruno Ace SC", cursive;
  letter-spacing: 5px;
}

.bounce {
  animation-name: letters-bounce-anim;
}

.bouncing-letters span {
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: 1;
  display: inline-block;
}

.fill-color {
  color: #ea4c46;
}

@keyframes letters-bounce-anim {
  20%,
  50%,
  80%,
  to {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1.75, 0.65);
  }
  45% {
    transform: scale(1.75, 0.65);
  }
  70% {
    transform: scale(1.25, 0.75);
  }
  90% {
    transform: scale(1.15, 0.85);
  }
}

@media screen and (max-width: 1400px) {
  .bouncing-letters {
    font-size: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .bouncing-letters {
    font-size: 50px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 739px) {
  .bouncing-letters {
    font-size: 40px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 580px) {
  .bouncing-letters {
    font-size: 30px;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 430px) {
  .bouncing-letters {
    font-size: 25px;
    letter-spacing: 0.2px;
  }
}

@media screen and (max-width: 355px) {
  .bouncing-letters {
    font-size: 20px;
    letter-spacing: 0.1px;
  }
}
